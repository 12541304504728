import React from 'react'
import PageWrapper from '../components/Structure/PageWrapper'
import Headline from '../components/Atoms/Headline'

const NotFoundPage = ({ location }) => (
	<PageWrapper
		title="Oops..."
		bonusContent={
			<div className="wrapper py-4 text-center">
				<Headline text="Well that wasn't by design." />
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</div>
		}
	/>
)

export default NotFoundPage
