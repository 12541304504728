import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from './Layout'
import Headline from '../Atoms/Headline'
import Image from '../Atoms/Image'
import MatrixBuilder from '../MatrixBuilder/MatrixBuilder'
import SEO from '../seo'
import {
	OuterWidth,
	FlexWrapper,
	OneThird,
	TwoThirds,
	OneHalf
} from '../shared'

const Box = styled.div`
	// ${tw`bg-grey-light`};
`
const PageWrapper = ({
	entry,
	mainImage,
	imageObject,
	matrixBuilder,
	mainContent,
	bonusContent,
	sidebarContent,
	location,
	title,
	toplede,
	topsidebar,
	pageTheme,
	...props
}) => {
	return (
		<Layout location={location} coloring={entry ? entry.pageTheme : pageTheme}>
			<SEO
				title={entry ? entry.title : title || 'Untitled'}
				keywords={[`pensa`, `design`, `product design`]}
			/>
			<Box>
				<div>
					{imageObject && (
						<Image
							imageObject={imageObject}
							title={entry.title}
							avatarStyle={false}
							aspectRatio={'16x9'}
							placeholder={'https://placehold.it/1312x738'}
							imgixParams={'?fit=crop&h=738&w=1312'}
							imageClasses={'w-full'}
							lazyClasses={'aspect-ratio-16/9'}
						/>
					)}
					<OuterWidth>
						{title && (
							<FlexWrapper className="pt-8 md:pt-12">
								<TwoThirds>
									<Headline text={title} />
								</TwoThirds>
							</FlexWrapper>
						)}
						{topsidebar && toplede ? (
							<FlexWrapper className="pb-8 md:pb-12">
								<TwoThirds className="text-2xl">{toplede || null}</TwoThirds>
								{topsidebar && (
									<OneThird className="bg-transparent">
										<FlexWrapper className="mt-8 md:mt-2">
											{topsidebar}
										</FlexWrapper>
									</OneThird>
								)}
							</FlexWrapper>
						) : null}
					</OuterWidth>
				</div>
				{matrixBuilder && <MatrixBuilder matrixBuilder={entry.matrixBuilder} />}
				{bonusContent}
			</Box>
		</Layout>
	)
}

export default PageWrapper
